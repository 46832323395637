import * as React from "react";

// Images
import Image1 from "./assets/img1.png";
import Image2 from "./assets/img2.png";
import Image3 from "./assets/img3.png";
import Image4 from "./assets/img4.png";
import Image5 from "./assets/img5.png";
import Image6 from "./assets/img6.png";
import Image7 from "./assets/img7.png";
import Image8 from "./assets/img8.png";
import Image9 from "./assets/img9.png";
import Image10 from "./assets/img10.png";
import Image11 from "./assets/img11.png";
import Image12 from "./assets/img12.png";
import Image13 from "./assets/img13.png";
import Tebex from "./assets/tebex.png";

// Components
import { Group, Button } from "@mantine/core";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const allImages = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
];

export default function App() {
  const [currentImage, setCurrentImage] = React.useState(Image13);
  const [fadeOut, setFadeOut] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [blur, setBlur] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex + 1 >= allImages.length ? 0 : prevIndex + 1
        );
        setCurrentImage(allImages[currentIndex]);
        setFadeOut(false);
      }, 150);
    }, 2500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="w-screen h-screen overflow-hidden bg-neutral-900 flex justify-center items-center">
      <img
        className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ${
          fadeOut ? "opacity-0" : "opacity-100"
        } ${blur ? "blur-sm" : ""}`}
        src={currentImage}
        alt="Not found..."
      />

      <Group position="center">
        <NewButton
          icon={<FontAwesomeIcon icon={faDiscord} />}
          link={"https://discord.gg/b89CJsmctE"}
          setBlur={setBlur}
        >
          Discord
        </NewButton>

        <NewButton
          icon={<img src={Tebex} alt="tebex not loaded" className="h-6" />}
          link={"https://vector-design-store.tebex.io/"}
          setBlur={setBlur}
        >
          Tebex
        </NewButton>
      </Group>
    </div>
  );
}

function NewButton({ children, icon, link, setBlur }) {
  return (
    <Button
      leftIcon={icon}
      color="dark"
      size="md"
      className="hover:bg-[#009290] duration-100 transition-all"
      onClick={(event) => {
        event.preventDefault();
        window.open(link, "_blank");
      }}
      onMouseEnter={() => setBlur(true)}
      onMouseLeave={() => setBlur(false)}
    >
      {children}
    </Button>
  );
}
