import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Mantine
import { MantineProvider } from '@mantine/core';

// Render Page
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MantineProvider theme={{ colorScheme: 'dark' }}>
            <App />
        </MantineProvider>
    </React.StrictMode>
);